import '../MobileStyles/MobileHomeContent.css';
import MobileHero from './MobileHero';
import MobileAbout from './MobileAbout';
import MobileContact from './MobileContact';
import MobileFeatures from './MobileFeatures';
import MobileProgress from './MobileProgress';

function MobileHomeContent() {
  return (

    <div className='mobile-home-content-container'>

      <MobileHero />

      <div className="mobile-graident-transition"></div>

      <MobileAbout />

      <MobileFeatures />

      <MobileProgress />

      <MobileContact />

    </div>
    
  );
}

export default MobileHomeContent;
import '../MobileStyles/MobileDiscordButton.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';

function DiscordButton() {
  return (

    <a href="https://discord.gg/SEnAgETsyc" className="MobileDiscordButton-container">
        <div className="mobile-db-items">
            <FontAwesomeIcon icon={faDiscord} className='mobile-discord-button' />
            <p className='mobile-db-text'>JOYSTIQ Central</p>
        </div>
    </a>
  );
};

export default DiscordButton;
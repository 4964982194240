import '../styles/HomeContent.css';

import About from './About';
import Contact from './Contact';
import Hero from './Hero';
import Features from './Features';
import Avatar from './Avatar';

function HomeContent() {
  return (

    <div className='HomeAboutContact-container'>

      <Hero />

      <div className="graident-transition"></div>

      <About />

      <Features />

      <Avatar />

      <Contact />

    </div>
    
  );
}

export default HomeContent;
import { useRef, useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';

const Avatar = () => {

    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    // Set default playback speed to 2x
    if (videoRef.current) {
      videoRef.current.playbackRate = 2.0;
    }
  }, []);

  const handlePlayPause = () => {
    videoRef.current.play();
    setIsPlaying(true);
    /*
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
        setIsPlaying(true);
      } else {
        videoRef.current.pause();
        setIsPlaying(false);
      }
    }
      */
  };

  const parentContainerStyles = {
    position: 'relative',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  };

  const avatarContainerStyles = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '36vw',
    marginTop: '3vw',
    background: 'none',
    paddingBottom: '200px',
    textAlign: 'center',
    position: 'relative',
    zIndex: 1,
  };

  const backgroundLayerStyles = {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'linear-gradient(to bottom, #0e5541, rgba(22,22,22,0) 50%), radial-gradient(circle, #0e5541 40%, rgba(22,22,22,1))',
    zIndex: 0,
  };

  const avatarTitle = {
    position: 'relative',
    fontFamily: 'controller-font, helvetica',
    fontSize: '2.8vw',
    background: 'linear-gradient(to bottom, #2eff58 50%, #16381d 100%)',
    WebkitBackgroundClip: 'text',
    color: 'transparent',
    margin: '0',
    textShadow: '1px 1px 1px rgba(0, 0, 0, 0.2)',
    zIndex: 1
  };

  const SVGcontainer = {
    position: 'relative'
  }

  const titleBottomBorder = {
    position: 'absolute',
    top: '110%',
    left: '0%',
    background: 'linear-gradient(to right, #0e5541, #2eff58, #0e5541)',
    width: '20vw',
    height: '2px',

  };

  const contentContainer = {
    height: '18vw',
    display: 'flex',
    marginTop: '10vw',
    gap: '40px',
    alignItems: 'center',
  };

  const imageHolder = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '3vh',
  };

  const cosmeticImage = {
    height: '12vw',
    width: '12vw',
    borderRadius: '10px',
    border: '1px solid rgba(113, 222, 128,0.2)',
    boxShadow: '-3px 3px 5px rgba(0,0,0,0.6)'
  };

  const videoContainer = {
    height: '32vw',
    width: '14.8vw',
    borderRadius: '2.3vw',
    border: '4px solid rgba(0,0,0,1)',
    boxShadow: '0px 0px 10px black',
  };

/*
  const videoContainer = {
    position: 'relative',
    width: '100%',
    maxWidth: '600px',
    margin: 'auto',
    backgroundColor: '#000',
    borderRadius: '8px',
    overflow: 'hidden'
  };
*/

  const playButton = {
    position: 'absolute',
    top: '20vw',
    left: '49.5vw',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'rgba(106, 222, 116, 0.4)',
    border: 'none',
    borderRadius: '50%',
    height: '80px',
    width: '80px',
    cursor: 'pointer',
    zIndex: 1,
    display: isPlaying ? 'none' : 'block' // Hide play button when video is playing
  };

  const enviroImage = {
    height: '9vw',
    width: '13vw',
    borderRadius: '10px',
    border: '1px solid rgba(113, 222, 128,0.2)',
    boxShadow: '3px 3px 5px rgba(0,0,0,0.6)'
  };

  const shoesImage = {
    height: '18vw',
    width: '12vw',
    borderRadius: '10px',
    border: '1px solid rgba(113, 222, 128,0.2)',
    boxShadow: '3px 3px 5px rgba(0,0,0,0.6)'
  };

  return (
    <div style={parentContainerStyles}>

      <div style={backgroundLayerStyles}></div>

      <div style={avatarContainerStyles}>
        
        <div style={SVGcontainer}>
            <p style={avatarTitle}>3D Avatars</p>
            <div style={titleBottomBorder} />
        </div>
        

        <div style={contentContainer}>
          <div style={imageHolder}>
            <img style={cosmeticImage} src="js-screenshots/cosmetic1.png" alt="app-screenshot" />
            <img style={cosmeticImage} src="js-screenshots/cosmetic2.png" alt="app-screenshot" />
          </div>

            <div>
                <video ref={videoRef} controls style={videoContainer} onClick={() => setIsPlaying(true)}>
                    <source src={`${process.env.PUBLIC_URL}/jsav.mp4`} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <button onClick={handlePlayPause} style={playButton}>
                    <FontAwesomeIcon icon={faPlay} style={{fontSize: '29px', color: 'lightgray'}} />
                </button>
            </div>


          <div style={imageHolder}>
            <img style={enviroImage} src="js-screenshots/environment.png" alt="app-screenshot" />
            <img style={shoesImage} src="js-screenshots/cosmetic3.png" alt="app-screenshot" />
          </div>
        </div>

      </div>

    </div>
  );
};

export default Avatar;

import '../styles/About.css';

function About() {

  const missionTitle = {
    fontFamily: 'controller-font, helvetica',
    fontSize: '2vw',
    background: 'linear-gradient(to bottom, #2eff58 50%, #16381d 100%)',
    WebkitBackgroundClip: 'text',
    color: 'transparent',
    margin: '0',
    textShadow: '1px 1px 1px rgba(0, 0, 0, 0.2)',
  };
  
  return (

    <div className="about-section">

      <div className="mission-container">

        <p style={missionTitle}>Our Mission</p>

        <p className="about-content">
        We envision a digital playground where gaming transcends screens, facilitating bonds, stories, and competitions. 
        Our tools - from content sharing to personalized avatars - are more than features; they are bridges to a global community. 
        With every game clip shared, avatar crafted, and leaderboard climbed, we inch closer to our goal: 
        a world where gaming is not just about playing, but about connecting, growing, and truly belonging. 
        </p>
        
      </div>

      <div className="graphic-container">
        <img src="websitegraphic.png" className='graphic-img' alt='graphic-with-features' />
      </div>

    </div>
    
  );
};

export default About;
import '../styles/DiscordButton.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';

function DiscordButton() {

  return (

    <a href="https://discord.gg/SEnAgETsyc" className="DiscordButton-container">
        <div className="DiscordButton-items">
            <FontAwesomeIcon icon={faDiscord} className='discord-button' />
            <p className='button-text'>JOYSTIQ Central</p>
        </div>
    </a>

  );
};

export default DiscordButton;
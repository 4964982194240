import '../MobileStyles/MobileFeatures.css';
import { useRef, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';

const MobileFeatures = () => {

    //---------------------------video-------------------------------------------
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);

    useEffect(() => {
        // Set default playback speed to 2x
        if (videoRef.current) {
        videoRef.current.playbackRate = 2.0;
        }
    }, []);

    const handlePlayPause = () => {
        videoRef.current.play();
        setIsPlaying(true);
    };

    const videoContainer = {
        position: 'relative',
        scrollSnapAlign: 'start',
        flexShrink: 0,
        height: '132vw',
        width: '62vw',
        borderRadius: '10vw',
        border: '4px solid rgba(0,0,0,1)',
        backgroundColor: 'rgba(0,0,0,1)',
        boxShadow: '0px 0px 10px black',
    };

    //---------------------------end video-------------------------------------------

    const containerRef = useRef(null);
    const handleSwipe = (direction) => {
        const container = containerRef.current;
        const scrollAmount = window.innerWidth * 0.3;
        
        const maxScrollLeft = container.scrollWidth - container.clientWidth;
        const currentScrollLeft = container.scrollLeft;

        if (direction === 'left') {
            const newScrollLeft = Math.min(currentScrollLeft + scrollAmount, maxScrollLeft);
            container.scrollTo({ left: newScrollLeft, behavior: 'smooth' });
        } else if (direction === 'right') {
            const newScrollLeft = Math.max(currentScrollLeft - scrollAmount, 0);
            container.scrollTo({ left: newScrollLeft, behavior: 'smooth' });
        }
    };

    let startX;
    let currentX;

    const handleTouchStart = (e) => {
        const touch = e.touches[0];
        startX = touch.clientX;
    };

    const handleTouchMove = (e) => {
        const touch = e.touches[0];
        currentX = touch.clientX;
    };

    const handleTouchEnd = () => {
        const deltaX = startX - currentX;
        if (deltaX > 50) {
            handleSwipe('left');
        } else if (deltaX < -50) {
            handleSwipe('right');
        }
    };

    const captionStyle = {
        position: 'absolute',
        fontFamily: 'controller-font, helvetica',
        fontSize: '4.1vw',
        textAlign: 'center',
        bottom: '40px',
        left: '0px',
        marginLeft: '15px',
        marginRight: '15px',
        color: 'white',
        backgroundColor: 'rgba(21, 107, 61, 0.9)',
        padding: '10px',
        borderRadius: '5px',
        border: '1px solid rgba(180,180,180,0.2)',
        boxShadow: '0px 0px 5px rgba(255,255,255,1)'
    };

    return (
        <div
            className='mobile-features-container'
            ref={containerRef}
            onTouchStart={(e) => handleTouchStart(e)}
            onTouchMove={(e) => handleTouchMove(e)}
            onTouchEnd={(e) => handleTouchEnd(e)}
        >
            <div className='image-slider'>
                <div className='slider-image-container' >
                    <img src='js-screenshots/homescreen.png' alt='appscreenshot' className='slider-image' />
                    <p style={captionStyle}>Share and explore content from your favorite games</p>
                </div>
                <div className='slider-image-container' >
                    <img src='js-screenshots/leaderboard.png' alt='appscreenshot' className='slider-image' />
                    <p style={captionStyle}>Compete for a spot on the leaderboard</p>
                </div>
                <div className='slider-image-container' >
                    <img src='js-screenshots/profile.png' alt='appscreenshot' className='slider-image' />
                    <p style={captionStyle}>Customize your profile and showcase your achievements</p>
                </div>

                <div className='slider-image-container' >
                    <video ref={videoRef} controls style={videoContainer} onClick={() => setIsPlaying(true)}>
                        <source src={`${process.env.PUBLIC_URL}/jsav.mp4`} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>

            </div>
        </div>
    );
};

export default MobileFeatures;

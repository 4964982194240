import './App.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './global.css';

import MobileHeader from './MobileComponents/MobileHeader';
import MobileHomeContent from './MobileComponents/MobileHomeContent';
import MobileFooter from './MobileComponents/MobileFooter';
import MobileModal from './MobileComponents/MobileModal';
import MobileTOSPP from './MobileComponents/MobileTOSPP'

import Header from './components/Header';
import HomeContent from './components/HomeContent';
import Footer from './components/Footer';
import TermsOfService from './components/TermsOfService';
import PrivacyStatement from './components/PrivacyPolicy';

/* TD

*/
/* ideas
  -react-tilt - has a background and foreground image that can be played with on hover
    -app icon + logo could work nicely with this
  -ThreeJS - or some 3D library
    -Interactive environment??
*/

function App() {
  
  //states for width detection and mobile modal
  const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 768px)").matches);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    const updateIsMobile = (e) => {
      setIsMobile(e.matches);
    };
    mediaQuery.addListener(updateIsMobile);
    // Clean up the listener when the component unmounts
    return () => mediaQuery.removeListener(updateIsMobile);
  }, []);

  return (

    <div>
      {isMobile ? (
        <Router>
          <div className="App">
            <MobileModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
            <MobileHeader setIsModalOpen={setIsModalOpen} />
            <Routes>
              <Route path="/" element={<MobileHomeContent />} />
              <Route path="/terms-of-service" element={<MobileTOSPP page={'TOS'} />} />
              <Route path="/privacy-statement" element={<MobileTOSPP page={'PP'} />} />
            </Routes>
            <MobileFooter />
          </div>
        </Router>
      ) : (
        <Router>
          <div className="App">
            <Header />
            <Routes>
              <Route path="/" element={<HomeContent />} />
              <Route path="/terms-of-service" element={<TermsOfService />} />
              <Route path="/privacy-statement" element={<PrivacyStatement />} />
            </Routes>
            <Footer />
          </div>
        </Router>
        )}
    </div>
  );
};

export default App;

import { useState, useEffect } from 'react';
import { CircleProgress } from 'react-gradient-progress';

const MobileProgressBar = () => {
    const [circleWidth, setCircleWidth] = useState(calculateWidth());

    // Function to calculate the width based on vw
    function calculateWidth() {
        return Math.min(window.innerWidth * 0.45); // Example: 50vw, max 300px
    };

    // Update width on window resize
    useEffect(() => {
        const handleResize = () => {
            setCircleWidth(calculateWidth());
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <CircleProgress
            width={circleWidth}
            percentage={68}
            strokeWidth={12}
            secondaryColor="lightgray"
            primaryColor={['#228f2f', '#4dd15d']}
            fontColor={'lightgray'}
            fontSize={28}
            fontFamily={'controller-font, helvetica'}
        />
    );
};

export default MobileProgressBar;
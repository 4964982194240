import ProgressBar from "./ProgressBar";
import Slider from "./Slider";

const Features = () => {

    const featureContainerStyles = {
        display: 'flex',
        justifyContent: 'center',
        height: '90vh',
        minHeight: '650px',
        background: '#0e5541',
        paddingBottom: '100px'
    };

    const alphaCard = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '40px',
        width: '70%',
        minWidth: '935px',
        maxWidth: '1200px',
        height: '40vw',
        maxHeight: '800px',
        minHeight: '600px',
        marginTop: '10px',
        paddingBottom: '20px',
        background: 'rgba(0,0,0,0.3)',
        borderRadius: '30px',
        boxShadow: '-2px -2px 5px rgba(200, 200, 200, 0.4), 2px 2px 5px rgba(0, 0, 0, 1)'
    };

    const sliderContainer = {
    };
    
    const progressContainer = {
        width: '20vw',
        minWidth: '260px',
        maxWidth: '444px',
        paddingRight: '5%',
        height: '40vw',
        minHeight: '500px',
        maxHeight: '768px',
        marginTop: '12%',
        textAlign: 'center'
    };

    const alphaTitle = {
        fontFamily: 'controller-font, helvetica',
        fontSize: '2vw',
        background: 'linear-gradient(to bottom, #2eff58 50%, #16381d 100%)',
        WebkitBackgroundClip: 'text',
        color: 'transparent',
        margin: '0',
        textShadow: '1px 1px 1px rgba(0, 0, 0, 0.2)',
    };

    const developmentSubtitle = {
        fontFamily: 'controller-font, helvetica',
        color: '#bababa',
        marginTop: '10px'
    };

  return (
    <div style={featureContainerStyles}>

        <div style={alphaCard}>

            <div style={sliderContainer}>
                <Slider />
            </div>
            
            <div style={progressContainer}>
                <p style={alphaTitle}>Alpha Release</p>
                <h3 style={{...developmentSubtitle, fontSize: '1vw'}}>Check out some features from our first alpha release. 
                    Our devs are working hard to get the app ready for full release this year!
                    </h3>
                <ProgressBar />
                <p style={{...developmentSubtitle, fontSize: '1.1vw'}}>Development Progress</p>
            </div>

        </div>

        
        
    </div>

  );
};

export default Features;

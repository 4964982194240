import MobileParticles from '../MobileComponents/MobileParticles';
import '../MobileStyles/MobileHero.css';
import MobileDiscordButton from '../MobileComponents/MobileDiscordButton';

const Hero = () => {
  
  return (

    <div className={"mobile-container-style"}>

        <MobileParticles id="tsparticlesmobile" />

        <div className="mobile-hero-content">

            <img src="iphoneImage3.png" alt="Logo" className="mobile-iPhoneImage" />
            
            <p className='mobile-welcome-message'>
            We're on a mission to evolve the gaming space, making it more vibrant, inclusive, and exhilarating for all. 
            To join the community or learn more about the project, click below and join the JOYSTIQ discord server!
            </p>

            <MobileDiscordButton />

        </div>
    </div>
  );
};

export default Hero;
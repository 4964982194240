import ParticlesBackground from './ParticlesBackground';
import '../styles/Hero.css';
import DiscordButton from './DiscordButton';

const Hero = () => {
  
  const joystiqStyle = {
    fontFamily: 'controller-font, helvetica',
    fontSize: '2.5vw',
    margin: '0',
  };

  const joystStyle = {
    background: 'linear-gradient(to bottom, #2eff58 50%, #16381d 100%)',
    WebkitBackgroundClip: 'text',
    color: 'transparent',
    textShadow: '1px 1px 1px rgba(0, 0, 0, 0.2)',
  };

  const iqStyle = {
    background: 'linear-gradient(to bottom, #ffffff 50%, #000 100%)',
    WebkitBackgroundClip: 'text',
    color: 'transparent',
    textShadow: '1px 1px 1px rgba(0, 0, 0, 0.2)',
  }


  return (

    <div className={"container-style"}>

        <ParticlesBackground id="tsparticles" />

        <div className="HeroContent">

            <img src="iphoneImage3.png" alt="Logo" className="iPhoneImage" />

            <div className="content">

              <p style={joystiqStyle}>
                <span style={joystStyle}>JOYST</span>
                <span style={iqStyle}>IQ</span>
              </p>
                
                <p className='welcome-message'>
                We're on a mission to evolve the gaming space, making it more vibrant, inclusive, and exhilarating for all. Interested in our private Alpha release? To join the community or learn more about the project, click below and join the JOYSTIQ discord server!
                </p>

                <DiscordButton />

            </div>

        </div>
    </div>
  );
};

export default Hero;

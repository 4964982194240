import '../styles/Footer.css';
import { Link } from 'react-router-dom';

function Footer() {

  return (

    <footer className='footer-container'>

      <p className='disclaimer'>
        JOYSTIQ is not endorsed by, directly affiliated with, maintained, authorized, or sponsored by any third-party brands or companies. 
        All product and company names are the registered trademarks of their original owners. The use of any trade name or trademark is for identification 
        and reference purposes only and does not imply any association with the trademark holder of their product brand.
      </p>

      <div className="link-section">
        <Link to="/terms-of-service" className='links'>Terms of Service</Link>
        <Link to="/privacy-statement" className='links'>Privacy Policy</Link>
      </div>

  </footer>
    
  );
};

export default Footer;
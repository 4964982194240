import '../MobileStyles/MobileTOSPP.css';
import { useEffect } from 'react';

function MobileTOSPP({page}) {

  useEffect(() => {
    window.scrollTo(0, 0); // Reset scroll position when this component mounts
  }, []);

  return (
    <>
    {page == 'TOS' ?
        
        <div className='mobile-tospp-container'>

            <div className='mobile-tospp-content-container'>

                <h1>JOYSTIQ Terms of Service</h1>
                <h3>Last updated 10/18/2023</h3>

                <h2>1. Introduction</h2>
                <p>
                Thank you for choosing JOYSTIQ. Please read these Terms of Service ("Terms") carefully, as they govern your access to and use of our services, 
                including our website, and any content, functionality, and services offered on or through them.
                </p>

                <h2>2. Acceptance of Terms</h2>
                <p>
                By using JOYSTIQ, you confirm that you accept these Terms and agree to be bound by them. If you disagree with any part of the terms, 
                you must not use our services.
                </p>

                <h2>3. Changes to Terms</h2>
                <p>
                JOYSTIQ reserves the right to modify these Terms at any time. We will notify users of any changes and your continued use of our services after 
                such notice will constitute acceptance of the updated terms.
                </p>

                <h2>4. User Registration</h2>
                <p>
                To access some features of JOYSTIQ, users might be asked to register an account. Users must provide accurate information and keep this information updated. 
                Users are responsible for maintaining the confidentiality of their account credentials.
                </p>

                <h2>5. Privacy Policy</h2>
                <p>
                All information we collect on JOYSTIQ is subject to our Privacy Policy. By using the site, you consent to all actions taken by us concerning 
                your information in compliance with the Privacy Policy.
                </p>

                <h2>6. Content and Intellectual Property</h2>
                <p>
                All content on JOYSTIQ, including texts, graphics, logos, and images, belongs to JOYSTIQ or its licensors and is protected by international 
                copyright, trademark, and other intellectual property laws.
                </p>

                <h2>7. User Responsibilities</h2>
                <p>
                You agree not to: (i) Use JOYSTIQ for illegal or unauthorized purposes. (ii) Attempt to gain unauthorized access to other computer systems or networks 
                connected to JOYSTIQ. (iii) Upload, post, or otherwise transmit through JOYSTIQ any viruses or other harmful, disruptive, or malicious files.
                </p>

                <h2>8. Termination</h2>
                <p>
                JOYSTIQ may terminate or suspend your access to our services immediately, without prior notice, for any reason, including, without limitation, 
                breach of these Terms.
                </p>

                <h2>9. Limitation of Liability</h2>
                <p>
                To the fullest extent permitted by law, JOYSTIQ shall not be liable for any damages, including, but not limited to, indirect, special, incidental, 
                consequential, or punitive damages arising out of or in connection with the use or inability to use our services.
                </p>

                <h2>10. Dispute Resolution</h2>
                <p>
                Any disputes related to these Terms, the Privacy Policy, or the use of JOYSTIQ shall be resolved through final and binding arbitration, rather than in court.
                </p>

                <h2>11. Governing Law</h2>
                <p>
                These Terms are governed by and construed in accordance with the laws of the State of Delaware, without regard to its conflict of law provisions.
                </p>

                <h2>12. Contact Information</h2>
                <p>
                For any questions or comments about these Terms or the services, please contact us at hello@joystiq.gg.
                </p>

            </div>

        </div>
        
    :
    
        <div className="mobile-tospp-container">
    
            <div className='mobile-tospp-content-container'>
    
                <h1 style={{marginBottom: "20px"}}>JOYSTIQ Privacy Policy</h1>
        
                <h2>1. Introduction</h2>
                <p>
                Welcome to JOYSTIQ. We prioritize your privacy and are committed to protecting the personal information you share and use on our platform. 
                This Privacy Policy describes the types of information we collect, how we use, disclose, and safeguard that information.
                </p>
        
                <h2>2. Information We Collect</h2>
                <p>
                    (i) Personal Identifiable Information (PII): This mainly includes your name, email address, username, and any other information you choose to provide that can identify you personally.
                    (ii) Non-Personal Information: This pertains to data that doesn’t identify an individual directly but helps us understand user behavior, such as device type, operating system, and IP address.
                </p>
        
                <h2>3. How We Use Your Information</h2>
                <p>
                    (i) To Provide and Improve Services: Your information assists us in delivering our services, responding to user needs, and enhancing user experience.
                    (ii) Communication: We may use your email address to send updates, news, or promotional material. You have the right to opt-out of receiving any promotional communication.
                    (iii) Research and Development: Aggregated and anonymized data helps us understand trends, user preferences, and patterns, aiding in improving and expanding our services.
                </p>
        
                <h2>4. Protection of Your Information</h2>
                <p>
                    We have robust security measures in place to prevent unauthorized access, alteration, disclosure, or destruction of your PII. This includes physical security measures, 
                    IT security solutions, and internal protocols.
                </p>
        
                <h2>5. Sharing Your Information</h2>
                <p>
                    We never sell, trade, or rent PII. We might share aggregated demographic info, which is not linked to any PII, for business development and analysis. If required by law, we may also share PII.
                </p>
        
                <h2>6. Third Party Services</h2>
                <p>
                    Our app may integrate third-party services or features. This Privacy Policy doesn’t govern third-party services, and we are not responsible for their practices. Please review their policies separately.
                </p>
        
                <h2>7. Children's Privacy</h2>
                <p>
                Our platform isn’t designed for individuals under 13. If we're informed that we’ve collected info from a child under 13, we’ll act promptly to delete that information.
                </p>
        
                <h2>8. Changes to the Privacy Policy</h2>
                <p>
                JOYSTIQ reserves the right to modify this Privacy Policy. Any updates will be communicated through our platform. Users are urged to review this policy periodically to stay informed.
                </p>
        
                <h2>9. Your Acceptance of These Terms</h2>
                <p>
                Using our platform signifies your acceptance of this Privacy Policy. If you disagree with any part of this policy, please refrain from using our services.
                </p>
        
                <h2>10. Contacting Us</h2>
                <p>
                Should you have questions regarding this Privacy Policy or our practices, please contact us at hello@joystiq.gg.
                </p>
    
            </div>

        </div>
    
    }
    </>

  );
};
  
export default MobileTOSPP;
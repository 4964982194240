import '../MobileStyles/MobileProgress.css';
import MobileProgressBar from '../MobileComponents/MobileProgressBar';

const MobileProgress = () => {

    const progressContainer = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '120vw',
        backgroundColor: '#0e5541'
    }

    const alphaCard = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        //justifyContent: 'center',
        //gap: '10px',
        width: '90vw',
        height: '95vw',
        paddingTop: '4vw',
        paddingBottom: '3vw',
        background: 'rgba(0,0,0,0.3)',
        borderRadius: '30px',
        boxShadow: '-2px -2px 5px rgba(200, 200, 200, 0.4), 2px 2px 5px rgba(0, 0, 0, 1)'
    };

    const alphaTitle = {
        fontFamily: 'controller-font, helvetica',
        fontSize: '8vw',
        background: 'linear-gradient(to bottom, #2eff58 50%, #16381d 100%)',
        WebkitBackgroundClip: 'text',
        color: 'transparent',
        margin: '0',
        textShadow: '1px 1px 1px rgba(0, 0, 0, 0.2)',
    };

    const developmentSubtitle = {
        fontFamily: 'controller-font, helvetica',
        color: '#bababa',
        marginTop: '10px'
    };

    return (
        <div style={progressContainer}>

            <div style={alphaCard}>

                <p style={alphaTitle}>Alpha Release</p>
                
                <h3 style={{...developmentSubtitle, fontSize: '4vw', paddingLeft: '1vw', paddingRight: '1vw'}}>Above are some features from our first alpha release.
                    Make sure to join the discord to be part of the beta release later this year!
                </h3>

                <MobileProgressBar />
                
                <p style={{...developmentSubtitle, fontSize: '4vw'}}>Development Progress</p>
            
            </div>

        </div>

    );
};

export default MobileProgress;
import '../styles/TermsOfService.css';
import { useEffect } from 'react';

function TermsOfService() {

  useEffect(() => {
    window.scrollTo(0, 0); // Reset scroll position when this component mounts
  }, []);

  return (

    <div className='TOS-PP-container'>

      <div className='content-container'>

        <h1>JOYSTIQ Terms of Service</h1>
        <h3>Last updated 10/18/2023</h3>

        <h2>1. Introduction</h2>
        <p>
          Thank you for choosing JOYSTIQ. Please read these Terms of Service ("Terms") carefully, as they govern your access to and use of our services, 
          including our website, and any content, functionality, and services offered on or through them.
        </p>


        <h2>2. Acceptance of Terms</h2>
        <p>
        By using JOYSTIQ, you confirm that you accept these Terms and agree to be bound by them. If you disagree with any part of the terms, 
        you must not use our services.
        </p>

        <h2>3. Changes to Terms</h2>
        <p>
        JOYSTIQ reserves the right to modify these Terms at any time. We will notify users of any changes and your continued use of our services after 
        such notice will constitute acceptance of the updated terms.
        </p>

        <h2>4. User Registration</h2>
        <p>
        To access some features of JOYSTIQ, users might be asked to register an account. Users must provide accurate information and keep this information updated. 
        Users are responsible for maintaining the confidentiality of their account credentials.
        </p>


        <h2>5. Privacy Policy</h2>
        <p>
          All information we collect on JOYSTIQ is subject to our Privacy Policy. By using the site, you consent to all actions taken by us concerning 
          your information in compliance with the Privacy Policy.
        </p>



        <h2>6. Content and Intellectual Property</h2>
        <p>
          All content on JOYSTIQ, including texts, graphics, logos, and images, belongs to JOYSTIQ or its licensors and is protected by international 
          copyright, trademark, and other intellectual property laws.
        </p>



        <h2>7. User Responsibilities</h2>
        <p>
        You agree not to: (i) Use JOYSTIQ for illegal or unauthorized purposes. (ii) Attempt to gain unauthorized access to other computer systems or networks 
        connected to JOYSTIQ. (iii) Upload, post, or otherwise transmit through JOYSTIQ any viruses or other harmful, disruptive, or malicious files.
        </p>



        <h2>8. Termination</h2>
        <p>
          JOYSTIQ may terminate or suspend your access to our services immediately, without prior notice, for any reason, including, without limitation, 
          breach of these Terms.
        </p>



        <h2>9. Limitation of Liability</h2>
        <p>
          To the fullest extent permitted by law, JOYSTIQ shall not be liable for any damages, including, but not limited to, indirect, special, incidental, 
          consequential, or punitive damages arising out of or in connection with the use or inability to use our services.
        </p>



        <h2>10. Dispute Resolution</h2>
        <p>
          Any disputes related to these Terms, the Privacy Policy, or the use of JOYSTIQ shall be resolved through final and binding arbitration, rather than in court.
        </p>



        <h2>11. Governing Law</h2>
        <p>
          These Terms are governed by and construed in accordance with the laws of the State of Delaware, without regard to its conflict of law provisions.
        </p>



        <h2>12. Contact Information</h2>
        <p>
          For any questions or comments about these Terms or the services, please contact us at hello@joystiq.gg.
        </p>



      </div>


    </div>


  );
};
  
export default TermsOfService;
import {useState, useEffect} from 'react';
import '../styles/Header.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';

function Header() {

  //states and useEffect hook handle hide on scroll
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [scrollDirection, setScrollDirection] = useState('none'); // 'up' or 'down'

  useEffect(() => {
      const handleScroll = () => {
          const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
          if (currentScrollTop > lastScrollTop) {
              setScrollDirection('down');
          } else {
              setScrollDirection('up');
          }
          setLastScrollTop(currentScrollTop <= 0 ? 0 : currentScrollTop);
      };

      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollTop]);

  const scrollToHome = () => {
    window.scrollTo({
      top: (0,0),
      behavior: 'smooth', // This enables smooth scrolling
    });
  };

  const scrollToAbout = () => {
    // Calculate the target scroll position (e.g., 90vh from the top)
    const targetPosition = window.innerHeight;
    
    window.scrollTo({
      top: targetPosition,
      behavior: 'smooth', // This enables smooth scrolling
    });
  };

  const headerStyles = {
    width: '80%',
    zIndex: '999',
    minWidth: '800px',
    position: 'fixed',
    top: scrollDirection === 'down' ? '-70px' : '10px',
    left: '50%',
    transform: 'translateX(-50%)',
    height: '70px',
    display: 'flex',
    alignItems: 'center',
    transition: 'top 0.3s ease',
    borderRadius: '100px',
    background: 'linear-gradient(to top, rgba(7, 43, 33, 1), rgba(19, 54, 51, 1))',
    border: '1px solid rgba(0, 252, 63,0.2)',
    boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.6)'
};

  return (
    <header style={headerStyles}>

      <nav className="header-content">

        <div className="left-buttons">
          <Link to="/" className='route-links'  onClick={scrollToHome}>Home</Link>
          <Link to="/" className='route-links' onClick={scrollToAbout}>About</Link>
        </div>

        <img src="LogoTransparent.png" alt="Logo" className="logo" />

        <div className="right-buttons">
          <a href="https://www.linkedin.com/company/joystiq-llc/"><FontAwesomeIcon icon={faLinkedin} /></a>
          <a href="https://twitter.com/JOYSTIQgg"><FontAwesomeIcon icon={faTwitter} /></a>
          <a href="https://www.instagram.com/joystiq_official/"><FontAwesomeIcon icon={faInstagram} /></a>
        </div>

      </nav>

      

    </header>
  );
};

export default Header;


import '../MobileStyles/MobileAbout.css';

function MobileAbout() {

  const missionTitle = {
    fontFamily: 'controller-font, helvetica',
    fontSize: '10vw',
    background: 'linear-gradient(to bottom, #2eff58 50%, #16381d 100%)',
    WebkitBackgroundClip: 'text',
    color: 'transparent',
    marginTop: '5vw',
    marginBottom: '5vw',
    textShadow: '1px 1px 1px rgba(0, 0, 0, 0.2)',
  };

  return (

    <div className="mobile-about-section">

      <p style={missionTitle}>Our Mission</p>

      <img src='websitegraphic.png' alt='mobile-graphicimg' className='mobile-websitegraphic' />

      <p className="mobile-about-content">
        We envision a digital playground where gaming transcends screens, facilitating bonds, stories, and competitions. 
        Our tools - from content sharing to personalized avatars - are more than features; they are bridges to a global community. 
      </p>
      

    </div>
    
  );
}

export default MobileAbout;
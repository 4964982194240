import '../styles/TermsOfService.css';
import { useEffect } from 'react';

function PrivacyStatement() {

  useEffect(() => {
    window.scrollTo(0, 0); // Reset scroll position when this component mounts
  }, []);

  return (
    <div className="TOS-PP-container">
    
      <div className='content-container'>

        <h1 style={{marginBottom: "20px"}}>JOYSTIQ Privacy Policy</h1>

        <h2>1. Introduction</h2>
        <p>
        Welcome to JOYSTIQ. We prioritize your privacy and are committed to protecting the personal information you share and use on our platform. 
        This Privacy Policy describes the types of information we collect, how we use, disclose, and safeguard that information.
        </p>

        <h2>2. Information We Collect</h2>
        <p>
          (i) Personal Identifiable Information (PII): This mainly includes your name, email address, username, and any other information you choose to provide that can identify you personally.
          (ii) Non-Personal Information: This pertains to data that doesn’t identify an individual directly but helps us understand user behavior, such as device type, operating system, and IP address.
        </p>

        <h2>3. How We Use Your Information</h2>
        <p>
          (i) To Provide and Improve Services: Your information assists us in delivering our services, responding to user needs, and enhancing user experience.
          (ii) Communication: We may use your email address to send updates, news, or promotional material. You have the right to opt-out of receiving any promotional communication.
          (iii) Research and Development: Aggregated and anonymized data helps us understand trends, user preferences, and patterns, aiding in improving and expanding our services.
        </p>

        <h2>4. Protection of Your Information</h2>
        <p>
          We have robust security measures in place to prevent unauthorized access, alteration, disclosure, or destruction of your PII. This includes physical security measures, 
          IT security solutions, and internal protocols.
        </p>

        <h2>5. Sharing Your Information</h2>
        <p>
          We never sell, trade, or rent PII. We might share aggregated demographic info, which is not linked to any PII, for business development and analysis. If required by law, we may also share PII.
        </p>

        <h2>6. Third Party Services</h2>
        <p>
          Our app may integrate third-party services or features. This Privacy Policy doesn’t govern third-party services, and we are not responsible for their practices. Please review their policies separately.
        </p>


        <h2>7. Children's Privacy</h2>
        <p>
        Our platform isn’t designed for individuals under 13. If we're informed that we’ve collected info from a child under 13, we’ll act promptly to delete that information.
        </p>

        <h2>8. Changes to the Privacy Policy</h2>
        <p>
        JOYSTIQ reserves the right to modify this Privacy Policy. Any updates will be communicated through our platform. Users are urged to review this policy periodically to stay informed.
        </p>

        <h2>9. Your Acceptance of These Terms</h2>
        <p>
        Using our platform signifies your acceptance of this Privacy Policy. If you disagree with any part of this policy, please refrain from using our services.
        </p>


        <h2>10. Contacting Us</h2>
        <p>
        Should you have questions regarding this Privacy Policy or our practices, please contact us at hello@joystiq.gg.
        </p>

      </div>
    </div>
  );
};

export default PrivacyStatement;
import '../MobileStyles/MobileModal.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import { useEffect } from 'react';

function MobileModal({isModalOpen, setIsModalOpen}) {

  useEffect(() => {
    if (isModalOpen) {
        document.body.classList.add('no-scroll');
    } else {
        document.body.classList.remove('no-scroll');
    }

    return () => {
        document.body.classList.remove('no-scroll');
    };
  }, [isModalOpen]);

  if (!isModalOpen) {
      return null;
  }

  const scrollToHome = () => {
    setIsModalOpen(false);
    const targetPosition = 0;

    window.scrollTo({
      top: targetPosition,
      behavior: 'smooth',
    });
  };

  const scrollToAbout = () => {
    setIsModalOpen(false);
    const targetPosition = window.innerWidth * 1.8;

    window.scrollTo({
      top: targetPosition,
      behavior: 'smooth',
    });
  };


  return (

    <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Mobile Menu"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
     
        
        <div className="modal-header">

          <FontAwesomeIcon
            icon={faXmark}
            style={{color: "#48d16f", width: '10vw', height: '10vw', padding: '10px'}}
            className="mobile-menu-button"
            onClick={() => setIsModalOpen(false)}
          />

          <img src='LogoTransparent.png' alt='js-logo' className='modal-logo' />

        </div>

        <div className="mobile-dividing-line" />


        <div className='modal-items'>
          
          <div className="modal-home-about">

            <Link to="/" className='modal-links' onClick={() => scrollToHome()}>Home</Link>
            <Link to="/" className='modal-links' onClick={() => scrollToAbout()}>About</Link>
            <Link to="/terms-of-service" className='modal-links' onClick={ () => setIsModalOpen(false) }>Terms of Service</Link>
            <Link to="/privacy-statement" className='modal-links' onClick={() => setIsModalOpen(false)}>Privacy Policy</Link>

          </div>

          <div className="modal-socials">
            <a href="https://www.linkedin.com/company/joystiq-llc/"><FontAwesomeIcon icon={faLinkedin} className='modal-social-icon' /></a>
            <a href="https://twitter.com/JOYSTIQgg"><FontAwesomeIcon icon={faTwitter} className='modal-social-icon' /></a>
            <a href="https://www.instagram.com/joystiq_official/"><FontAwesomeIcon icon={faInstagram} className='modal-social-icon' /></a>
          </div>

        </div>
        
      </Modal>
    
  );
}

export default MobileModal;
import '../MobileStyles/MobileContact.css';

function About() {

  const contactTitle = {
    marginTop: '5vh',
    marginBottom: '2vh',
    fontFamily: 'controller-font, helvetica',
    fontSize: '8vw',
    background: 'linear-gradient(to bottom, #2eff58 50%, #16381d 100%)',
    WebkitBackgroundClip: 'text',
    color: 'transparent',
    textShadow: '1px 1px 1px rgba(0, 0, 0, 0.2)',
};

  return (
    <div className="mobile-contact-section">

      <p style={contactTitle}>Contact the Team</p>

      <div className="mobile-contact-entries">
        <div className="mobile-category">
          <img src='carmine-pic.jpeg' alt='carminesheadshot' className='mobile-team-picture' />
          <h2>Carmine Cioffi</h2>
          <h3>Chief Executive Officer</h3>
          <p>ccioffi@joystiq.gg</p>
          <a href='https://www.linkedin.com/in/carmine-cioffi-987a4a188/'>
            <img src="LinkedInLogo.png" alt="li-logo" className="mobile-linkedin-logo" />
          </a>
        </div>

        <div className="mobile-category">
          <img src='stephen-pic.jpeg' alt='stephensheadshot' className='mobile-team-picture' />
          <h2>Stephen Sottosanti</h2>
          <h3>Chief Technology Officer</h3>
          <p>ssottosanti@joystiq.gg</p>
          <a href='https://www.linkedin.com/in/stephen-sottosanti'>
            <img src="LinkedInLogo.png" alt="li-logo" className="mobile-linkedin-logo" />
          </a>
        </div>


        <div className="mobile-category">
          <img src='connor-pic.jpeg' alt='connorsheadshot' className='mobile-team-picture' />
          <h2>Connor Sottosanti</h2>
          <h3>Chief Product Officer</h3>
          <p>csottosanti@joystiq.gg</p>
          <a href='https://www.linkedin.com/in/connor-sottosanti-3725201b1'>
            <img src="LinkedInLogo.png" alt="li-logo" className="mobile-linkedin-logo" />
          </a>
        </div>

        <p className='mobile-general-inq'>General inquiries can be sent to hello@joystiq.gg</p>
      </div>

    </div>
  );
};

export default About;
import '../styles/Contact.css';

function About() {

  const contactTitle = {
    marginTop: '10vh',
    marginBottom: '8vh',
    fontFamily: 'controller-font, helvetica',
    fontSize: '36px',
    background: 'linear-gradient(to bottom, #2eff58 50%, #16381d 100%)',
    WebkitBackgroundClip: 'text',
    color: 'transparent',
    textShadow: '1px 1px 1px rgba(0, 0, 0, 0.2)',
};

  return (

    <div className="contact-section">

      <p style={contactTitle}>Contact the Team</p>

      <div className="contact-entries">

        <div className="category">
          <img src="carmine-pic.jpeg" alt="teamimage" className="contact-picture" />
          <h2>Carmine Cioffi</h2>
          <h3>Chief Executive Officer</h3>
          <p>ccioffi@joystiq.gg</p>
          <a href='https://www.linkedin.com/in/carmine-cioffi-987a4a188/'>
            <img src="LinkedInLogo.png" alt="li-logo" className="linkedin-logo" />
          </a>

        </div>

        <div className="category">
        <img src="stephen-pic.jpeg" alt="teamimage" className="contact-picture" />
          <h2>Stephen Sottosanti</h2>
          <h3>Chief Technology Officer</h3>
          <p>ssottosanti@joystiq.gg</p>
          <a href='https://www.linkedin.com/in/stephen-sottosanti'>
            <img src="LinkedInLogo.png" alt="li-logo" className="linkedin-logo" />
          </a>
        </div>

        <div className="category">
        <img src="connor-pic.jpeg" alt="teamimage" className="contact-picture" />
          <h2>Connor Sottosanti</h2>
          <h3>Chief Product Officer</h3>
          <p>csottosanti@joystiq.gg</p>
          <a href='https://www.linkedin.com/in/connor-sottosanti-3725201b1'>
            <img src="LinkedInLogo.png" alt="li-logo" className="linkedin-logo" />
          </a>
        </div>

      </div>

      <h3 className='general-inq-statement'>General inquiries can be sent to hello@joystiq.gg</h3>

    </div>
  );
};

export default About;
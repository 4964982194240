import React from 'react';
import '../MobileStyles/MobileHeader.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { useState, useEffect } from 'react';
import MobileModal from './MobileModal';

function MobileHeader({setIsModalOpen}) {

  //states and useEffect hook handle hide on scroll
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [scrollDirection, setScrollDirection] = useState('none'); // 'up' or 'down'

  useEffect(() => {
      const handleScroll = () => {
          const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
          if (currentScrollTop > lastScrollTop) {
              setScrollDirection('down');
          } else {
              setScrollDirection('up');
          }
          setLastScrollTop(currentScrollTop <= 0 ? 0 : currentScrollTop);
      };

      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollTop]);

  const mobileHeaderStyles = {
    position: 'fixed',
    top: scrollDirection === 'down' ? '-70px' : '0px',
    width: '100%',
    transition: 'top 0.3s ease',
    zIndex: '5',
    background: 'linear-gradient(to top, rgba(7, 43, 33, 1), rgba(19, 54, 51, 1))',
    boxShadow: '0px 0px 10px rgba(0,0,0,1)',
};

  const joystiqStyle = {
    fontFamily: 'controller-font, helvetica',
    fontSize: '7vw',
    paddingLeft: '20vw',
    paddingTop: '10px', paddingBottom: '10px',
    margin: '0',
  };

  const joystStyle = {
    background: 'linear-gradient(to bottom, #2eff58 50%, #16381d 100%)',
    WebkitBackgroundClip: 'text',
    color: 'transparent',
    textShadow: '1px 1px 1px rgba(0, 0, 0, 0.2)',
  };

  const iqStyle = {
    background: 'linear-gradient(to bottom, #ffffff 50%, #000 100%)',
    WebkitBackgroundClip: 'text',
    color: 'transparent',
    textShadow: '1px 1px 1px rgba(0, 0, 0, 0.2)',
  };

  return (
    <header style={mobileHeaderStyles}>

      <div className="mobile-header-items">

        <FontAwesomeIcon
          icon={faBars}
          size="2xl"
          style={{ color: "#2eff58" }}
          className="mobile-menu-button"
          onClick={() => setIsModalOpen(true)}
        />

        <p style={joystiqStyle}>
            <span style={joystStyle}>JOYST</span>
            <span style={iqStyle}>IQ</span>
        </p>

      </div>

      <div className="mobile-dividing-line" />

      <MobileModal />


    </header>
  );
};

export default MobileHeader;

import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import 'react-awesome-slider/dist/custom-animations/cube-animation.css';
import '../styles/custom-slider-styles.css';

const Slider = () => {

    const containerStyle = {
        width:'30vw',
        height: '35vw',
        minWidth: '400px',
        minHeight: '470px',
        maxHeight: '634px'
    };

    const captionStyle = {
        position: 'absolute',
        fontFamily: 'controller-font, helvetica',
        fontSize: '1.1vw',
        textAlign: 'center',
        bottom: '40px',
        left: '0px',
        marginLeft: '15px',
        marginRight: '15px',
        color: 'white',
        backgroundColor: 'rgba(21, 107, 61, 0.9)',
        padding: '10px',
        borderRadius: '5px',
        border: '1px solid rgba(180,180,180,0.2)',
        boxShadow: '0px 0px 5px rgba(255,255,255,1)'
    };

    const imageStyle = {
        marginLeft: '7vw',
        width: '16vw',
        minWidth: '220px',
        maxWidth: '308px',
        height: '33vw',
        minHeight: '452px',
        maxHeight: '634px',
        background: 'transparent'
    };

    return (
        <AwesomeSlider style={containerStyle} animation="cubeAnimation">
            <div data-src={'js-screenshots/homescreen.png'} style={imageStyle}>
                <p style={captionStyle}>Share and explore content from your favorite games</p>
            </div>
            <div data-src={'js-screenshots/leaderboard.png'} style={imageStyle}>
                <p style={captionStyle}>Compete for a spot on the leaderboard</p>
            </div>
            <div data-src={'js-screenshots/profile.png'} style={imageStyle}>
                <p style={captionStyle}>Customize your profile and showcase your achievements</p>
            </div>
        </AwesomeSlider>
    );
};

export default Slider;